<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm7 md5>
                <v-card style="max-width: 400px;margin: 0% auto">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>重置密码</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field id="password1" prepend-icon="lock" name="pswd1" label="密码" :rules="[rules.required, rules.counter]" v-model="password" type="password" maxlength=12></v-text-field>
                            <v-text-field id="password2" prepend-icon="lock" name="pswd2" label="重复密码" :rules="[rules.required, rules.counter]" v-model="password2" type="password" maxlength=12></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="EventDone">确定</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>

import {passwordset} from '@/api'
import { event_emit } from '@/eventbus'
import gdata from '@/gdata';

export default {
    name: 'PasswordSet',
    props: {},
    components: {},
    data() {
        return {
            token: '',
            password: '',
            password2: '',
            rules: {
                required: value => !!value || '必须',
                counter: value => value.length <= 12 && value.length >= 6 || '6 ~ 12个字符',
            }
        }
    },
    methods: {
        EventDone() {
            if (!this.password || !this.password2) {
                event_emit(this, 'snackbar', { type: 'warning', msg: '请检查填写的项目' });
                return
            }
            if (this.password.length < 6 || this.password.length > 12) {
                event_emit(this, 'snackbar', { type: 'warning', msg: '密码要求6 ~ 12个字符' });
                return
            }
            if (this.password !== this.password2) {
                event_emit(this, 'snackbar', { type: 'warning', msg: '两次密码必须相同' });
                return
            }

            var postdata = {'password': this.password, 'password2': this.password2 }
            passwordset({}, postdata, res=>{
                console.log(res)
                window.localStorage.removeItem('me');
                gdata.medata = null;
                event_emit(this, 'signout');
            })
        },
    },
    mounted: function() {},
    beforeDestroy() {
        this.token = null;
        this.password = null;
        this.password2 = null;
        this.rules = null;
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>